<template>
  <layout-horizontal>
    <router-view />
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar-horizontal :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import NavbarHorizontal from '../components/NavbarHorizontal.vue'

export default {
  components: {
    LayoutHorizontal,
    NavbarHorizontal,
  },
}
</script>
