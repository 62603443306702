<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    // Hide pages depands on page accesses
    let pageNameCh
    const pageAccessesNames = localStorage.getItem('pageAccessesNames')
    for (let i = navMenuItems.length - 1; i >= 0; i--) {
      if (navMenuItems[i].children) {
        for (let j = navMenuItems[i].children.length - 1; j >= 0; j--) {
          pageNameCh = `${navMenuItems[i].header}: ${navMenuItems[i].children[j].title}`
          if (!pageAccessesNames.includes(pageNameCh)) {
            navMenuItems[i].children.splice(j, 1)
          }
        }
      } else if (!pageAccessesNames.includes(navMenuItems[i].title)) {
        navMenuItems.splice(i, 1)
      }
    }

    return {
      navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
